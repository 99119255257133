import { Layout, Form, Input, Button, Row, Col, message } from 'antd';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import './login.page.scss';
import { login } from "../actions/auth";
import { useTranslation } from 'react-i18next';

const Login = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onFinish = (values) => {
        console.log('Success:', values);
        dispatch(login(values.email, values.password)).then(() => {
            message.success(t('login.message.success'));
            navigate('/dashboard');
        }).catch((error) => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        message.error(JSON.stringify(errorInfo));
    };

    return <Layout className="crm-layout crm-layout--login" style={{ minHeight: '100vh' }}>
        <Form
            name="basic"
            labelCol={{
                // span: 12,
            }}
            wrapperCol={{
                span: 6,
                offset: 9
            }}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Row>
                <Col span={6} offset={9}>
                    <h1 className="crm-layout__title">{t('login.headings.title')}</h1>
                </Col>
            </Row>
            <Form.Item
                // label="Username"
                name="email"
                rules={[
                    {
                        required: true,
                        message: t('login.field.email.required')
                    },
                ]}
            >
                <Input placeholder={t('login.field.email.placeholder')} />
            </Form.Item>

            <Form.Item
                // label="Password"
                name="password"
                rules={[
                    {
                        required: true,
                        message: t('login.field.password.required')
                    },
                ]}
            >
                <Input.Password placeholder={t('login.field.password.placeholder')} />
            </Form.Item>

            {/* <Form.Item
                name="remember"
                valuePropName="checked"
                wrapperCol={{
                    offset: 9,
                    span: 6,
                }}
                place
            >
                <Checkbox>Remember me</Checkbox>
            </Form.Item> */}

            <Form.Item
                wrapperCol={{
                    offset: 9,
                    span: 6,
                }}
            >
                <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                    {t('login.buttons.login')}
                </Button>
            </Form.Item>
        </Form>
    </Layout>;
};

export default Login;